/** @jsx jsx */
import { jsx } from "theme-ui";
import { useCanvas } from "../lib/useCanvas";

const Canvas = ({ description, button }) => {
    const [canvasRef, canvasWidth, canvasHeight] = useCanvas(1000, 1100);

    return (
        <div className="canvas" sx={{ position: "relative", zIndex: 0 }}>
            <canvas
                className="App-canvas"
                style={{ width: "100%", height: "100%" }}
                ref={canvasRef}
                width={canvasWidth}
                height={canvasHeight}
            />
            <div
                className="information"
                sx={{
                    position: "absolute",
                    left: 0,
                    right: "50%",
                    top: "50%",
                    transform: "translateY(-50%)",
                    variant: "text.body.main"
                }}
            >
                {description}
                {button && button}
            </div>
        </div>
    );
};

export default Canvas;
