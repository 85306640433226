/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import { motion } from "framer-motion";

//Components
import SEO from "../components/seo";
import Layout from "../components/layout";
import Section from "../components/section";
import VideoAnimation from "../components/showreel";
import FigureGrid from "../components/figureGrid";
import Button from "../components/button";
import IconGrid from "../components/iconGrid";
import Canvas from "../components/pixelatedCanvas";

const IndexPage = props => {
    const { data, errors } = props;

    if (errors) {
        return <div>Error</div>;
    }

    const site = (data || {}).site;
    const page = (data || {}).page;

    if (!site) {
        throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
        );
    }

    return (
        <Layout>
            <SEO title={site.title} description={site.description} keywords={site.keywords} />
            <Section name="hero" hero>
                <h1 sx={{ variant: "text.title.main" }}>{page.title}</h1>
                <p sx={{ pt: [5, 5, 6], variant: "text.body.main" }}>{page.description}</p>
                <VideoAnimation
                    webm={page.showreel._rawWebm}
                    fallback={page.showreel._rawFallback}
                    aspectRatio={page.showreel.aspectratio}
                    alt={page.showreel.alt}
                />
            </Section>
            <Section name="projects" shaded>
                <h1 sx={{ variant: "text.title.main" }}>Highlighted</h1>
                <h1 sx={{ variant: "text.title.main" }}>
                    <span sx={{ variant: "text.outline" }}>Projects</span>
                </h1>
                <FigureGrid list={page._rawHighlights} extend left showInfo isLink />
                <Button message="Want to see more!" link="/projects" center />
            </Section>
            <Section name="skills">
                <h1 sx={{ variant: "text.title.main" }}>
                    Some stuff I <span sx={{ variant: "text.outline" }}>Use</span>
                </h1>
                <IconGrid iconSize="5rem" small />
                <Button message="Check out my full skillset" link="/skillset" center />
            </Section>
            <Section name="about">
                <Canvas
                    description={page.about}
                    button={<Button message="About me" link="/about" />}
                />
            </Section>
        </Layout>
    );
};

export const query = graphql`
    query IndexPageQuery {
        site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            title
            description
            keywords
        }
        page: sanityHomePage(_id: { regex: "/(drafts.|)homePage/" }) {
            title
            description
            _rawHighlights
            about
            showreel {
                _rawWebm
                _rawFallback
                aspectratio
                caption
                alt
            }
        }
    }
`;

export default IndexPage;
