/** @jsx jsx */
import { jsx } from "theme-ui";
import { useEffect, useRef, useState } from "react";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";

import { getPaddingFromAspectRatio } from "../lib/helpers";
import { videoAssetFor } from "../lib/video-url";

const VideoAnimation = ({ webm, fallback, aspectRatio, alt, caption }) => {
    if (!webm || !fallback) {
        return null;
    }

    const videoEl = useRef(null);
    const [fullscreen, setFullscreen] = useState(false);
    const [showCredits, setShowCredits] = useState(false);

    useEffect(() => {
        const video = videoEl.current;
        console.log(videoEl);
        fullscreen ? video.pause() : video.play();
    }, [fullscreen]);

    const webmAsset = videoAssetFor(webm);
    const fallbackAsset = videoAssetFor(fallback);

    useEffect(() => {
        const loadLazed = async () => {
            const lozad = require("lozad");
            const observer = lozad();
            observer.observe();
        };

        loadLazed();
    });

    return (
        <AnimateSharedLayout>
            <figure
                className="auto-video"
                cursor-scale="scale-up"
                cursor-type="icon"
                sx={{
                    height: "100%",
                    width: "100%",
                    cursor: "pointer",
                    m: 0,
                    pt: [4, 4, 4, 4, 5]
                }}
                onClick={() => setFullscreen(true)}
            >
                <video
                    ref={videoEl}
                    sx={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain"
                    }}
                    title={alt}
                    className="lozad"
                    loop
                    muted
                    autoPlay
                    playsInline
                >
                    <source src={webmAsset.url} type={`video/${webmAsset.extension}`} />
                    <source src={fallbackAsset.url} type={`video/${fallbackAsset.extension}`} />
                </video>
                <figcaption>{caption}</figcaption>
            </figure>
            <AnimatePresence>
                {fullscreen && (
                    <div
                        className="fullscreen-video"
                        sx={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            zIndex: 100,
                            width: "100%",
                            height: "100%",
                            bg: "black",
                            variant: "text.body.main",
                            ":hover": {
                                ".close": {
                                    opacity: 1
                                }
                            }
                        }}
                    >
                        <div
                            className="close"
                            sx={{
                                position: "absolute",
                                top: 1,
                                right: 1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: 101,
                                bg: "#000000b3",
                                backdropFilter: "saturate(180%) blur(5px)",
                                width: "60px",
                                height: "60px",
                                borderRadius: "100%",
                                opacity: [1, 1, 0],
                                transition: "500ms",
                                cursor: "pointer",
                                color: "white"
                            }}
                            onClick={() => setFullscreen(false)}
                        >
                            X
                        </div>
                        <div  sx={{
                                position: "absolute",
                                top: 2,
                                left: 1,
                                zIndex: 101,
                                transition: "500ms",
                                cursor: "pointer",
                                opacity: [1, 1, 0],
                                color: "white"
                            }} className="attribution" onClick={() => setShowCredits(!showCredits)}>
                            Show Credits
                            <motion.ul initial={{opacity: 0}} animate={showCredits ? {opacity: 1} : {opacity: 0}}>
                                <li>Music provided by <a sx={{color: "white"}} href="https://eoun.com">https://eoun.com</a></li>
                                <li>Track: prestosound - Animation</li>
                                <li>Watch: <a sx={{color: "white"}} href="https://youtu.be/Z4l8dwtYOtI">https://youtu.be/Z4l8dwtYOtI</a></li>
                                <li>Free Download: <a sx={{color: "white"}} href="https://eoun.com/track/animation">https://eoun.com/track/animation</a></li>
                            </motion.ul>
                        </div>
                        <video
                            sx={{ height: "100%", width: "100%", objectFit: "contain" }}
                            title={alt}
                            className="lozad"
                            loop
                            autoPlay
                            controls
                            playsInline
                        >
                            <source src={webmAsset.url} type={`video/${webmAsset.extension}`} />
                            <source
                                src={fallbackAsset.url}
                                type={`video/${fallbackAsset.extension}`}
                            />
                        </video>
                    </div>
                )}
            </AnimatePresence>
        </AnimateSharedLayout>
    );
};

export default VideoAnimation;